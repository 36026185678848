import React from 'react'

const Hamburger = ({ toggleMenu, className }) => {
  return (
    <button onClick={toggleMenu} className={className}>
      <div className="hamburger__line hamburger__line--primary" />
      <div className="hamburger__line hamburger__line--secondary" />
      <div className="hamburger__line hamburger__line--tertiary" />
    </button>
  )
}

export default Hamburger
