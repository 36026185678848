import React from 'react'
import { StaticQuery, Link, graphql } from 'gatsby'

const SubmenuLink = ({ link, i, clickLink }) => {
  return (
    <li key={i} className="px-4 py-2">
      <Link
        onClick={clickLink}
        className="text-charcoal no-underline transition hover:opacity-75"
        to={link.link_path}
      >
        {link.link_title}
      </Link>
    </li>
  )
}

class Submenu extends React.Component {
  render() {
    const { submenu, clickLink } = this.props
    return (
      <StaticQuery
        query={graphql`
          query {
            allPrismicMenuLink(
              sort: { fields: [data___order_header], order: ASC }
            ) {
              edges {
                node {
                  data {
                    link {
                      uid
                    }
                    title
                    order_header
                    order_footer
                    submenu_item {
                      link_title
                      link_path
                    }
                  }
                }
              }
            }
          }
        `}
        render={data => {
          if (
            data.allPrismicMenuLink.edges[submenu].node.data.submenu_item
              .length === 0
          )
            return
          return (
            <div className="nav__submenu">
              <ul className="list-reset relative pb-2 lg:py-4">
                {data.allPrismicMenuLink.edges[
                  submenu
                ].node.data.submenu_item.map((item, i) => {
                  return (
                    <SubmenuLink
                      key={i}
                      link={item}
                      clickLink={clickLink}
                      data={
                        data.allPrismicMenuLink.edges[submenu].node.data.link
                          .uid
                      }
                    />
                  )
                })}
              </ul>
            </div>
          )
        }}
      />
    )
  }
}

export default Submenu
