import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import SEOLayout from './SEO/Layout'

import Header from './header'
import Footer from './footer'
import MobileMenu from './MobileMenu'
import '../styles/index.css'

class Layout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      menuIsOpen: false,
    }
  }
  componentDidMount = () => {
    let vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)

    window.addEventListener('resize', () => {
      // We execute the same script as before
      let vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)

      if (window.innerWidth >= 990) {
        this.setState({
          menuIsOpen: false,
        })
      }
    })
  }

  noScroll = () => {
    document.documentElement.classList.add('no-scroll')
    document.body.classList.add('no-scroll')
  }

  resumeScroll = () => {
    document.documentElement.classList.remove('no-scroll')
    document.body.classList.remove('no-scroll')
  }

  toggleMenu = () => {
    this.setState({
      menuIsOpen: !this.state.menuIsOpen,
    })

    if (this.state.menuIsOpen) {
      this.resumeScroll()
    } else {
      this.noScroll()
    }
  }

  render() {
    const { children, location } = this.props
    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={data => (
          <>
            <SEOLayout location={location} />
            <div
              className={
                location &&
                (location.pathname === '/contact' ||
                  location.pathname === '/contact/')
                  ? 'bg-grey theme-grey overflow-hidden'
                  : 'bg-white text-charcoal  overflow-hidden'
              }
            >
              <Header
                menuIsOpen={this.state.menuIsOpen}
                toggleMenu={this.toggleMenu}
                siteTitle={data.site.siteMetadata.title}
              />
              <div>{children}</div>
              <Footer
                location={location}
                siteTitle={data.site.siteMetadata.title}
              />
              <MobileMenu
                siteTitle={data.site.siteMetadata.title}
                menuIsOpen={this.state.menuIsOpen}
                resumeScroll={this.resumeScroll}
                noScroll={this.noScroll}
              />
            </div>
          </>
        )}
      />
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
