module.exports = {
  site: {
    name: 'Forté Health',
    url: 'https://www.fortehealth.co.nz', // No trailing slash
    title: 'Forté Health',
    description:
      'Our private hospital offers state of the art surgical and patient facilities and an expert team focused on providing the best patient care possible.', // Website description used for RSS feeds/meta description tag.
    keywords:
      'hospital, private, surgical, care, patients, forté, health, surgeons, nursing, christchurch, new zealand, south island',
  },
  manifest: {
    shortName: 'Forté Health', // homescreen (PWA). under 12 characters.
    themeColor: '#bad80a', // progress theme colors
    bgColor: '#bad80a',
    icon: 'src/images/Forte_FaviconBird.png',
  },
  prismic: {
    repository: 'fortehealth001',
  },
  google: {
    analytics: {
      trackingId: 'G-EG6KYYBJ6M',
    },
  },
}
