import React from 'react'

const GreenStar = () => {
  return (
    <svg viewBox="0 0 221.26 57.94">
      <path
        d="M30.39,15.19c-6.65,0-15.2,8.55-15.2,15.2,0-6.65-8.55-15.2-15.19-15.2C6.64,15.19,15.19,6.64,15.19,0c0,6.64,8.55,15.19,15.2,15.19m10.72,6.26V27H41c-1.44-4-5.45-6.13-9.56-6.13-7.45,0-11.41,6-11.41,13,0,7.35,3.65,13.05,11.41,13.05a9.92,9.92,0,0,0,9.56-6h.1v6.06c0,1.74-.15,9.3-9.41,9.3-4.31,0-8-1.85-8.42-6.53h-2c.32,6.12,4.68,8.22,10.34,8.22,11.41.37,11.41-9.4,11.41-11.45v-25ZM22,33.83c0-5.6,3.09-11.3,9.46-11.3s9.61,5.39,9.61,11.3-3.19,11.36-9.61,11.36c-6.22,0-9.46-5.14-9.46-11.36M49.85,21.44h-2V47.86h2V33.42c.2-4,1-5.6,3-7.61a8.88,8.88,0,0,1,7.29-2.56v-2c-4.78-.2-9.09,2.57-10.12,6h-.15ZM82,34.91c.36-7.56-3.33-14.09-11.61-14.09-8,0-11.76,7.05-11.66,14.29-.1,7.3,4,13.37,11.66,13.37,6.27,0,10.39-3.39,11.41-9.56h-2a9.13,9.13,0,0,1-9.46,7.86c-6.53,0-9.82-5.65-9.71-11.87Zm-21.32-1.7c.41-5.24,3.86-10.68,9.71-10.68,6.07,0,9.56,5.13,9.66,10.68Zm44.69,6.66a9,9,0,0,1-9.23,6.91c-6.54,0-9.82-5.65-9.72-11.87h21.34c.36-7.56-3.35-14.09-11.62-14.09-8,0-11.78,7.05-11.67,14.29-.11,7.3,4,13.37,11.67,13.37,6.27,0,10.38-3.39,11.41-9.56h-2c-.06.32-.13.64-.22.95m-19-6.66c.42-5.24,3.86-10.68,9.72-10.68,6.06,0,9.55,5.13,9.66,10.68Zm25,14.65h1.95V33.57c0-6.48,3.6-11,9.5-11,7.21,0,7.51,5.9,7.51,8.63v16.7h2V31.52c0-3.24-.1-10.69-9.41-10.69-4.11,0-8.12,2.31-9.45,6.26h-.11V21.44h-1.95Z"
        fill="#85c446"
      />
      <path
        d="M143.36,39.29c0,3.16,2.7,4.44,5.55,4.44,2.09,0,4.74-.82,4.74-3.37,0-2.19-3-3-8.2-4.07-4.18-.92-8.36-2.4-8.36-7,0-6.72,5.81-8.45,11.47-8.45s11.05,1.93,11.62,8.4h-6.89c-.2-2.8-2.34-3.56-4.94-3.56-1.63,0-4,.3-4,2.44,0,2.6,4.07,3,8.2,3.93s8.36,2.5,8.36,7.38c0,6.93-6,9.18-12,9.18s-12.07-2.29-12.38-9.28Zm76.33-18.5a9.32,9.32,0,0,0-8.41,5.61h-.11V21.51h-6.88v5.23a9.56,9.56,0,0,1,.12,1.55V42a12.55,12.55,0,0,0,.61,4.78l.68,1.08h5.83V36c0-4.64,1.83-8.46,7.13-8.46a13.37,13.37,0,0,1,2.6.26V21.05a5.43,5.43,0,0,0-1.57-.26M173.23,42.45c-2.45,0-3.06-.61-3.06-3.06v-13h6.25A9.76,9.76,0,0,1,180,21.51h-9.79V14.05s-1.89,2-7.24,2V41.89c0,5.25,3.88,6.27,8.31,6.27a36.57,36.57,0,0,0,6.3-.7,9.17,9.17,0,0,1-2.43-5.16,11.82,11.82,0,0,1-1.88.15m21.36-4.79c0,1.58-.21,6.07-5.86,6.07-2.35,0-4.44-.67-4.44-3.37s2-3.41,4.29-3.87,4.78-.46,6-1.53Zm-9.48-8c.25-2.85,2-4,4.79-4,2.55,0,4.69.46,4.69,3.56S190.46,32,186,32.67s-9,2-9,8c0,5.4,4,7.89,9,7.89,3.21,0,6.57-.86,8.86-3.26a10.57,10.57,0,0,0,.51,2.56h7.34c-.66-1.08-.92-3.47-.92-5.87V28.29c0-6.32-6.31-7.5-11.56-7.49-5.92,0-12,2-12.38,8.81Z"
        fill="#3f454f"
      />
    </svg>
  )
}

export default GreenStar
