import React from 'react'
import { Link } from 'gatsby'

import Nav from './Nav'
import Hamburger from './Hamburger'
import Logo from './SVG/Logo'

class Header extends React.Component {
  render() {
    const { toggleMenu, menuIsOpen } = this.props
    return (
      <>
        <div
          className={`relative flex justify-between items-end pb-5 pt-8 lg:py-0 lg:pt-10 px-3 border-b border-transparent lg:border-0 lg:px-8 xl:px-14 transition-bg ${
            menuIsOpen ? 'border-b border-grey-light bg-grey z-10' : ''
          }`}
        >
          <div className="z-50 lg:mb-5">
            <Link to="/">
              <div
                className={`logo logo--header ${
                  menuIsOpen ? 'logo--white' : ''
                }`}
              >
                <Logo />
              </div>
            </Link>
          </div>
          <Nav className="hidden lg:block" />
          <Hamburger
            toggleMenu={toggleMenu}
            className={
              menuIsOpen ? 'hamburger hamburger--is-open' : 'hamburger'
            }
          />
        </div>
      </>
    )
  }
}

export default Header
