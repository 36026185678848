import React from 'react'
import Submenu from './Submenu'
import { StaticQuery, Link, graphql } from 'gatsby'

const activeStyle = {
  fontWeight: 'bold',
}

const NavLink = ({ link, title, clickLink, item, i, submenu }) => (
  <li
    key={i}
    className={`lg:px-3 xl:px-4 mb-6 lg:mb-0 lg:pb-5 ${
      item.node.data.submenu_item !== '' ? 'nav__link--has-sub' : ''
    }`}
  >
    <Link
      to={link}
      onClick={clickLink}
      className="text-xl lg:text-base link leading-none"
      activeStyle={activeStyle}
    >
      {title}
    </Link>
    {item.node.data.submenu_item !== '' && (
      <Submenu submenu={submenu} clickLink={clickLink} />
    )}
  </li>
)

class Nav extends React.Component {
  clickLink = () => {
    if (window.innerWidth < 990) {
      if (this.props.menuIsOpen) {
        this.props.resumeScroll()
      } else {
        this.props.noScroll()
      }
    }
  }

  render() {
    const { className } = this.props
    return (
      <StaticQuery
        query={graphql`
          query {
            allPrismicMenuLink(
              sort: { fields: [data___order_header], order: ASC }
            ) {
              edges {
                node {
                  data {
                    link {
                      uid
                    }
                    title
                    order_header
                    order_footer
                    submenu_item {
                      link_title
                      link_path
                    }
                  }
                }
              }
            }
          }
        `}
        render={data => {
          return (
            <nav className={className}>
              <ul className="list-reset lg:flex lg:-mx-3 xl:-mx-4 text-base tracking-base my-10 lg:my-0 text-lg lg:text-base">
                {data.allPrismicMenuLink.edges.map((item, i) => {
                  const slug = item.node.data.link.uid
                  let link = `/${slug === 'home' ? '' : slug}`

                  return (
                    <NavLink
                      key={i}
                      clickLink={this.clickLink}
                      link={link}
                      title={item.node.data.title}
                      item={item}
                      submenu={i}
                    />
                  )
                })}
              </ul>
            </nav>
          )
        }}
      />
    )
  }
}

export default Nav
