import React from 'react'

import Nav from './Nav'
import Logo from './SVG/Logo'

const MobileMenu = ({ menuIsOpen, siteTitle, resumeScroll, noScroll }) => (
  <div
    className={`mobile-menu absolute pin-t pin-l h-screen w-screen bg-grey transition-opacity px-3 pt-19 lg:hidden overflow-scroll ${
      menuIsOpen ? 'opacity-100' : 'opacity-0 z--10'
    }`}
  >
    <div className={`mb-8 ${menuIsOpen ? 'block' : 'hidden'}`}>
      <Nav
        className="theme-grey border-b border-grey-light"
        menuIsOpen={menuIsOpen}
        resumeScroll={resumeScroll}
        noScroll={noScroll}
      />
      <div
        className={`logo logo--footer mt-12 mb-8 ${
          menuIsOpen ? 'logo--white' : ''
        }`}
      >
        <Logo />
      </div>
      <p className="text-xs text-white opacity-50 tracking-sm">
        &copy; {siteTitle} {new Date().getFullYear()}
      </p>
    </div>
  </div>
)

export default MobileMenu
